<!--保存-->
<template>
  <el-dialog :visible.sync="show" title="创建商城模板" width="40%">
    <div class="flex-column col-center">
      <!-- form -->
      <el-form :model="form" ref="form" label-width="90px" style="width: 80%">
        <el-form-item label="名称" prop="name" verify>
          <el-input type="text" v-model="form.name" size="small" />
        </el-form-item>

        <el-form-item label="行业" prop="shopIndustryID" verify>
          <el-select v-model="form.shopIndustryID" placeholder="请选择行业" size="small">
            <el-option v-for="item in mallIndustryList" :key="item.IndustryID" :label="item.Title"
              :value="item.IndustryID">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="备注" prop="remark" verify class="form-item-none">
          <el-input v-model="form.remark" maxlength="30" placeholder="输入备注" show-word-limit type="textarea"
            size="small" />
        </el-form-item>

        <el-form-item label="封面" prop="templateCover" verify class="form-item-none">
          <Imgpond v-model="form.templateCover" :count="1" />
        </el-form-item>
      </el-form>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button type="primary" round style="width: 140px" @click="submit">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getShopIndustries, addShopTemplate } from '@/api/goods'
import { mapGetters, mapMutations } from "vuex";
import { rojectModel } from "@/config/project";

export default {
  name: "CreateDialog",

  data() {
    return {
      mallIndustryList: [],
      show: false,
      form: {
        name: '',
        shopIndustryID: '',
        remark: '',
        templateCover: ''
      },
    };
  },
  mounted() {
    this.getIndustryList()
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  methods: {
    ...mapMutations(["setProject"]),
    getIndustryList() {
      getShopIndustries().then(res => {
        this.mallIndustryList = [...res]
      })
    },
    open() {
      this.show = true;
      this.$nextTick(() => {
        this.$refs["form"]?.resetFields()
      })
    },

    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.createTemplate();
        }
      });
    },
    /**
     * 创建商城模版
     */
    createTemplate() {
      const data = {
        ...this.form
      }
      addShopTemplate(data).then(res => {
        if (res) {
          this.$emit('ok')
          this.show = false
          // project.id = id;
          // this.setProject(project);
          // this.$router.push({ name: "mall" });
        }
      })
    },
    /**
     * 创建商城
     */
    // async create() {
    //   let map = new Map();
    //   mallTypeList.map((item) => map.set(item.type, item.logo));

    //   let project = {
    //     ...this.$cloneDeep(rojectModel),
    //     ...this.form,
    //     ...{ userId: this.userInfo.userId, logo: map.get(this.form.type) },
    //   };
    //   let { status, id } = await addProject(project);

    //   if (status == "10000") {
    //     project.id = id;
    //     this.setProject(project);
    //     this.$router.push({ name: "mall" });
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-button--primary) {
  background: $color-theme !important;
}

.form-item-none {
  border-color: transparent;
}
</style>